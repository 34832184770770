import React, { useEffect, useState } from 'react'
import cancel from '../../assets/images/cancel.png';
import './modal.css'
import Back from '../../assets/images/arrow-left.png'
import axios from 'axios';
import { serverAPI } from '../..';
import { Link, useNavigate } from 'react-router-dom';

export default function Searchpage({ onClose }) {
    const navigate = useNavigate();


    const [searchInput, setSearchInput] = useState('');
    const [services, setServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);

    useEffect(() => {
        // Replace 'your-api-endpoint' with the actual API endpoint that serves your services data.
        axios.get(`${serverAPI}/services`)
            .then((response) => {
                setServices(response.data);
            })
            .catch((error) => {
                console.error('Error fetching services:', error);
            });
    }, []);

    useEffect(() => {
        const filtered = services.filter((service) =>
            service.service_name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredServices(filtered);
    }, [searchInput, services]);

    const handleServiceClick = (service) => {
        // Construct the URL with the required parameters
        const url = `/service?name=${encodeURIComponent(service.category)}&id=${service.categoryId}&service=${encodeURIComponent(service.subcategory)}`;

        // Navigate to the specified URL
        navigate(url);
    }
    return (
        <>
            <div className='search-cont'>
                <div className='nav_search' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "95%", marginBottom: "25px" }}>
                    <img src={Back} onClick={() => {navigate(-1)}} alt='' style={{ paddingTop: "0px", width: "21px", height: "20px" }} />
                    <input
                        type="text"
                        placeholder="Search for services..."
                        className="search-input"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>
                <div className='divdr'></div>
                {/* <h4>Trending</h4> */}
                <div className='service_btn' >
                    <div style={{ display: searchInput === '' ? 'none' : 'block', height: "85vh", overflowY: 'scroll', width: "100%" }}>
                        {filteredServices.length === 0 ? (
                            <div className="service_btn"> <div className="srvc_1">No results found </div></div>
                        ) : (
                            <div className="service_btn" style={{ marginTop: '10px', }} >
                                {
                                    filteredServices.map((service, index) => (
                                        <div className="srvc_1" key={index} onClick={() => { handleServiceClick(service) }} style={{ cursor: "pointer" }}><p style={{ margin: "0", padding: "0", textAlign: "left" }}>{service.service_name}</p></div>
                                    ))
                                }
                            </div>

                        )}
                    </div>

                </div>

            </div>
        </>
    )
}
