import React, { useState, useEffect } from 'react'
import './profile.css'
import User from '../../assets/images/Maskgroup3.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import usericon from '../../assets/images/icon1.svg'
import Next from '../../assets/images/next.png'
import Bookoing from '../../assets/images/icon2.svg'
import Address from '../../assets/images/icon3.svg'
import Allorder from '../../assets/images/icon4.svg'
import Help from '../../assets/images/icon5.svg'
import Track from '../../assets/images/icon6.svg'
import Wallet from '../../assets/images/icon7.svg'
import Terms from '../../assets/images/icon8.svg'
import Refund from '../../assets/images/icon9.svg'
import About from '../../assets/images/icon10.svg'
import Logout from '../../assets/images/icon11.svg'
import axios from 'axios';
import { serverAPI } from '../..'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
import HashLoader from "react-spinners/HashLoader";
import LoginModal from '../login/loginModal'

export default function Myaccount() {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpens, setIsPopupOpens] = useState(false);
  const [userData, setUserData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(true);
  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
        setPageLoaded(false);
      }
    } catch (err) {
      setIsPopupOpens(true);
    }
  };
  useEffect(() => {
    getRootUser();
  }, []);
  const getFormattedAddress = () => {
    if (userData?.address.home && userData?.address.home.length > 0) {
      return userData?.address.home[0].address;
    } else if (userData?.address.other && userData?.address.other.length > 0) {
      return userData?.address.other[0].address;
    } else {
      return 'No address available';
    }
  };
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpens(false);
  };

  const closePopups = () => {
    navigate('/')
    setIsPopupOpens(false);
  };

  const [loadLogout, setLoadLogOut] = useState(true);
  const location = useLocation(); // Get the current location
  // Define a function to check if a route is active
  const isRouteActive = (route) => location.pathname === route;


  const logOutRootUser = async () => {
    setLoadLogOut(false)
    try {
      const res = await axios.get(`${serverAPI}/auth/logout`, {
        withCredentials: true,
      });

      if (res.status === 200) {
        toast.success("Logout Succesfully 😃!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setTimeout(() => {
          setLoadLogOut(true)
          navigate('/')
        }, 2500);
      }
    } catch (err) {
    }
  };
  return (
    <>
      {
        pageLoaded ?
          <>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
              <HashLoader
                color="#981313"
                size={50}
              />

            </div>
            <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

          </>
          :
          <div className='ac-parent'>
            <div className='ac-banner'>
              <div className='banner-overley'>
                <div className='user'>
                  <div className='user-box'>
                    <img src={User} alt=''/>
                  </div>
                  <div className='user-detail'>
                    {
                      userData?.name ?  <h4>{userData?.name}</h4>: <h4> </h4>
                    }
                    
                    <p>{userData?.phone}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='menu-box'>
              <div className='menu-div'>
                <ul>
                  <Link to='/profile'><li className={isRouteActive('/profile') ? 'active' : ''}>
                    <img src={usericon} className='usericon' alt='' />
                    Profile
                    <img src={Next} className='nexticon' height='12
                            px' width='14px' alt='' />

                  </li></Link>
                  <Link to='/currentbooking'><li className={isRouteActive('/currentbooking') ? 'active' : ''}>
                    <img src={Bookoing} className='usericon' alt='' />
                    Current bookings
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='/manageaddress'><li className={isRouteActive('/manageaddress') ? 'active' : ''}>
                    <img src={Address} className='usericon' alt='' />
                    Manage address
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='/allorders'><li className={isRouteActive('/allorders') ? 'active' : ''}>
                    <img src={Allorder} className='usericon' alt='' />
                    All orders
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='/gethelp'><li className={isRouteActive('/gethelp') ? 'active' : ''}>
                    <img src={Help} className='usericon' alt='' />
                    Get help
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  {/* <Link to='/currentbooking'><li className={isRouteActive('/currentbooking') ? 'active' : ''}>
                    <img src={Track} className='usericon' alt='' />
                    Track your booking
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link> */}
                  <Link to='/se_wallet'> <li className={isRouteActive('/se_wallet') ? 'active' : ''}>
                    <img src={Wallet} className='usericon' alt='' />
                    SalonEase Wallet
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='/terms-conditions'> <li className={isRouteActive('/se_wallet') ? 'active' : ''}>
                    <img src={Terms} className='usericon' alt='' />
                    Terms & conditions
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='/refund-policy'> <li className={isRouteActive('/se_wallet') ? 'active' : ''}>
                    <img src={Refund} className='usericon' alt='' />
                    Refund policy
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='/about-us'> <li className={isRouteActive('/se_wallet') ? 'active' : ''}>
                    <img src={About} className='usericon' alt='' />
                    About us
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                  <Link to='#logout' > <li className={isRouteActive('/se_wallet') ? 'active' : ''} onClick={()=>{logOutRootUser()}}>
                    <img src={Logout} className='usericon' alt='' />
                    Logout
                    <img src={Next} className='nexticon' height='12px' width='14px' alt='' />
                  </li></Link>
                </ul>



              </div>

            </div>

          </div>
      }
    </>
  )
}
