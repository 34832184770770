import React, { useState, Component } from 'react'
import Slider from "react-slick";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/images/Card1.png'
import Slid1 from '../../assets/images/Card1.png'
import Slid2 from '../../assets/images/Card2.png'
import Slid3 from '../../assets/images/Card3.png'
import Slid4 from '../../assets/images/Card4.png'
import Slid5 from '../../assets/images/Card5.png'
import Slid6 from '../../assets/images/Card6.png'



import './newhome.css'
import { Link } from 'react-router-dom';

export default class CardSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ]
        };

        return (
            <>
                <div className='selling-parent'>
                    <h2><span>Top selling</span>  packages </h2>

                    {/* <div className='selling-scroll'>

                        <div className='selling-card'>
                            <img src={Slid1} />
                        </div>
                        <div className='selling-card mleft'>
                            <img src={Slid2} />
                        </div>
                        <div className='selling-card mleft'>
                            <img src={Slid3} />
                        </div>
                        <div className='selling-card mleft'>
                            <img src={Slid4} />
                        </div>
                        <div className='selling-card mleft'>
                            <img src={Slid5} />
                        </div>
                        <div className='selling-card mleft'>
                            <img src={Slid6} />
                        </div>
                        <div className='selling-card mleft'>
                            <img src={Slid7} />
                        </div>

                    </div> */}
                    <div style={{ margin: '0px 10px' }}>
                        <Slider {...settings}>
                            <div className='selling-card' >
                                <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864'>
                                    <img src={Slid1} alt='' />
                                </Link>
                            </div>
                            <div className='selling-card' >
                                <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864'>
                                    <img src={Slid2} alt='' />
                                </Link>
                            </div>
                            <div className='selling-card' >
                                <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864'>
                                    <img src={Slid3} alt='' />
                                </Link>
                            </div>
                            <div className='selling-card' >
                                <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864'>
                                    <img src={Slid4} alt='' />
                                </Link>
                            </div>
                            <div className='selling-card' >
                                <Link to='/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788'>
                                    <img src={Slid5} alt='' />
                                </Link>
                            </div>
                            <div className='selling-card' >
                                <Link to='/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788&service=Monthly%20Essentials%20Package'> 
                                <img src={Slid6} alt='' />
                                </Link>
                            </div>
                            {/* <div className='selling-card'>
                            <img src={Slid7} alt='' />
                        </div> */}

                        </Slider>
                    </div>


                </div>
            </>
        )
    }
}
