import React, { Component, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import './newhome.css'
import './Silders.css'

import SilderImage1 from '../../assets/BannerHomePageTop/bnr1.png'
import SilderImage2 from '../../assets/BannerHomePageTop/bnr2.png'
import SilderImage3 from '../../assets/BannerHomePageTop/bnr3.png'
import SilderImage4 from '../../assets/BannerHomePageTop/bnr4.png'
import SilderImage5 from '../../assets/BannerHomePageTop/bnr5.png'
import SilderImage6 from '../../assets/BannerHomePageTop/bnr6.png'
import SilderImage7 from '../../assets/BannerHomePageTop/bnr7.png'

import Location from '../../assets/images/loctn.png'
import Loc from '../../assets/images/location.png'
import Next from '../../assets/images/arrow-down.png'
import Precision from '../../assets/images/precision.png'
import Search from '../../assets/images/srch.png'
import Arrowup from '../../assets/images/arrow-up.png'
import LocationPopup from "./locationpopup";
import axios from "axios";
import { serverAPI } from "../..";
import { Link, useNavigate } from 'react-router-dom';

export default function SimpleSlider() {
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();
    const serverDropRef = useRef();



    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const [addkey, setAddKey] = useState(null);

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('addkey'));
        if (items) {
            setAddKey(items);
        }
    }, []);

    const [searchInput, setSearchInput] = useState('');
    const [services, setServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);

    useEffect(() => {
        // Replace 'your-api-endpoint' with the actual API endpoint that serves your services data.
        axios.get(`${serverAPI}/services`)
            .then((response) => {
                setServices(response.data);
            })
            .catch((error) => {
                setServices([]);
                console.error('Error fetching services:', error);
            });
    }, []);

    useEffect(() => {
        const filtered = services.filter((service) =>
            service.service_name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredServices(filtered);
    }, [searchInput, services]);

    const handleServiceClick = (service) => {
        // Construct the URL with the required parameters
        const url = `/service?name=${encodeURIComponent(service.category)}&id=${service.categoryId}&service=${encodeURIComponent(service.subcategory)}`;

        // Navigate to the specified URL
        navigate(url);
    }

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [searchInput]);

    const handleOutsideClick = (e) => {
        if (
            e.target.className !== "search-input"
        ) {
            setSearchInput('');
            // Hide the server_drop div here
        }
    };
    return (

        <>

            <div className="banner">
                <Slider {...settings}>
                   
                    <div className="slid-img">
                        <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864&service=Any%204%20%20services%20%40999%20'>
                            <img src={SilderImage2} alt="" />

                        </Link>
                    </div>
                    <div className="slid-img">
                        <Link to='/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788&service=Salon%20Ease%20Exclusive%20Packages'>
                            <img src={SilderImage3} alt="" />
                        </Link>
                    </div>
                    <div className="slid-img">
                        <Link to='/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788'>
                            <img src={SilderImage4} alt="" />
                        </Link>
                    </div >
                    <div className="slid-img">
                        <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864&service=Any%205%20Service%20%401649'>
                            <img src={SilderImage5} alt="" />
                        </Link>
                    </div>
                    <div className="slid-img">
                        <Link to='/service'>
                            <img src={SilderImage6} alt="" />
                        </Link>
                    </div>
                    <div className="slid-img">
                        <Link to='/service?name=Waxing&id=651be151909e90359c9a5c8f&service=Rica%20Waxology' >
                            <img src={SilderImage7} alt="" />
                        </Link>
                    </div>

                </Slider>
                <div className="overlay">
                    <div className='inerr'>
                        {/* <div className="location_dropdown"   >
                            <div className="loc_menu">
                                <img src={Loc} width='19px' height='19px' style={{ marginRight: '10px' }} alt="" />
                                {
                                    addkey ? <div className="loc_menul"><h3>{addkey}</h3> </div> : <h3>Location</h3>
                                }

                            </div>

                            <img className="arrow" src={Next} width='16px' height='16px' style={{ marginRight: '10px', marginLeft: '10px' }} alt="" />
                        </div> */}


                        <div style={{ display: "flex", flexDirection: "column" }} >
                            <div className="search-container" >
                                <input
                                    type="text"
                                    placeholder="Search for services"
                                    className="search-input"
                                    value={searchInput}

                                    onChange={handleInputChange}
                                />

                                <button className="search-button">
                                    <i className="fas fa-search"></i>
                                </button>


                            </div>
                            <div className="server_drop" style={{ display: searchInput === '' ? 'none' : 'block', height: "350px" }}>
                                {filteredServices.length === 0 ? (
                                    <div className="service_btn"> <div className="srvc_1">No results found </div></div>
                                ) : (
                                    <div className="service_btn" style={{ marginTop: '10px', }} >
                                        {
                                            filteredServices.map((service, index) => (
                                                <div className="srvc_1" key={index} onClick={() => { handleServiceClick(service) }} style={{ cursor: "pointer" }}><p style={{ margin: "0", padding: "0", textAlign: "left" }}>{service.service_name}</p></div>
                                            ))
                                        }
                                    </div>

                                )}
                            </div>
                        </div>

                    </div>


                </div>
                <LocationPopup isOpen={isPopupOpen} onClose={closePopup} />

            </div>

        </>

    );
}
