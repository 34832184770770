import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Menu from '../assets/images/hamburger.svg'
import './profile_header.css'
import '../components/modal/sidebarmodal.css'
import LoginModal from '../components/login/loginModal'
import Logo from '../assets/images/SElogo.png'
import Arrowup from '../assets/images/arrow-up.png'
import SearchLocation from "../components/home/searchlocation";
import axios from "axios";
import { serverAPI } from "..";
// import usericon from '../assets/images/Maskgroup3.png'
import Next from '../assets/images/next.png'
// import Bookoing from '../assets/images/Maskgroup1.png'
// import Address from '../assets/images/Maskgroup4.png'
// import Allorder from '../assets/images/Maskgroup2.png'
// import Help from '../assets/images/Maskgroup7.png'
// import Track from '../assets/images/Maskgroup6.png'
// import Wallet from '../assets/images/Maskgroup5.png'
import usericon from '../assets/images/icon1.svg'
import Bookoing from '../assets/images/icon2.svg'
import Address from '../assets/images/icon3.svg'
import Allorder from '../assets/images/icon4.svg'
import Help from '../assets/images/icon5.svg'
import Track from '../assets/images/workwithus.svg'
import Home from '../assets/images/home_menu_s.svg'
import Wallet from '../assets/images/icon7.svg'
import Footer6 from '../assets/images/f1.png'
import Footer7 from '../assets/images/f2.png'
import Footer8 from '../assets/images/f3.png'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
import User from '../assets/images/Maskgroup3.png'

export default function Homeheader() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
        localStorage.setItem("uuid", JSON.stringify(userData._id));
      } else {
        localStorage.removeItem("uuid");
        localStorage.removeItem("subNo");
        localStorage.removeItem("subService");
      }
    } catch (err) {
      localStorage.removeItem("uuid");
      localStorage.removeItem("subNo");
      localStorage.removeItem("subService");
    }
  };
  useEffect(() => {
    getRootUser();
  }, []);


  useEffect(() => {
    const userUui = JSON.parse(localStorage.getItem("uuid"));
    if (userUui) {
      setPageLoaded(true);
    } else {
      localStorage.removeItem("uuid");
      localStorage.removeItem("subNo");
      localStorage.removeItem("subService");
      setPageLoaded(false);
    }
  }, [navigate, userData]);

  const location = useLocation(); // Get the current location
  // Define a function to check if a route is active
  const isRouteActive = (route) => location.pathname === route;


  const [click, setClick] = useState(false);
  const cartItemsCount = useSelector((state) => state.cart.products.length);

  const handleClick = () => setClick(!click);


  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };



  // login popup

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [isPopupOpens, setIsPopupOpens] = useState(false);

  const openPopups = () => {
    setIsPopupOpens(true);
  };

  const closePopups = () => {
    setIsPopupOpens(false);
  };

  const [openMenu, setOpenMenu] = useState(false);
  const loginMenuRef = useRef(null);

  // Function to handle the click event outside of the "Login-menu"
  const handleClickOutside = (event) => {
    if (openMenu && loginMenuRef.current && !loginMenuRef.current.contains(event.target)) {
      // Check if the click event did not originate from the "My Account" element
      if (event.target.classList.contains("nav-links")) {
        return;
      }
      setOpenMenu(false);
    }
  };

  // Add a click event listener to the document when the menu is open
  useEffect(() => {
    if (openMenu) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };




  }, [openMenu]);
  const [loadLogout, setLoadLogOut] = useState(true);

  const logOutRootUser = async () => {
    setLoadLogOut(false)
    try {
      const res = await axios.get(`${serverAPI}/auth/logout`, {
        withCredentials: true,
      });

      if (res.status === 200) {
        toast.success("Logout Succesfully 😃!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setTimeout(() => {
          localStorage.removeItem('uuid');
          setLoadLogOut(true);
          navigate('/');
          window.location.reload();
        }, 2500);
      }
    } catch (err) {
    }
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };


  return (
    <>
      <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

      <nav className="navbar Navbar ">
        <div className="nav-Container">
          <NavLink to="/" className="nav-logo">
            <div className="logo_img"><img src={Logo} alt="desktop_logo" /></div>
            <p className="tageline2" >| </p>
            <p className="tageline">Salon service at home</p>

          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-items" >
              <NavLink
                exact
                activeClassName="active"
                className="nav-links"
                to='/work-with-us'
              >
                Register as a professional
              </NavLink>
            </li>
            {pageLoaded ?
              <li
                className="nav-items"
                onMouseEnter={handleOpenMenu}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from propagating to the document
                }}
              >
                <a 
                  activeClassName="active" className="nav-links">
                  My Account
                </a>
              </li>

              :
              <li className="nav-items" onClick={openPopups} >
                Login/Signup
              </li>
            }
            <li className="nav-items">
              {
                userData ? <NavLink
                  exact
                  to="/gethelp"
                  activeClassName="active"
                  className="nav-links"

                >
                  Help</NavLink> : <NavLink
                    exact
                    to="/help"
                    activeClassName="active"
                    className="nav-links"

                  >
                  Help</NavLink>
              }
            </li>

          </ul>
          {openMenu ?
            <div className="Login-menu" ref={loginMenuRef}>
              <img src={Arrowup} width='15px' className="arrowup" alt="" />
              <ul>
                <li style={{ paddingTop: '20px', height: '50px' }}>{userData ? userData?.phone : ''}</li>
                <NavLink to='/profile'><li>Go to my account</li></NavLink>
                <NavLink to='/cart'><li>Cart</li></NavLink>
                <li style={{ cursor: 'pointer' }} onClick={logOutRootUser}>Logout</li>
              </ul>

            </div>
            : null}


          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>

        </div>
      </nav>


      <div className="mobile_active">
        <div className="mobile_nav">
          <div className="mobile_nav_logo">
            <img src={Menu} className="menu" alt="mobile_logo" onClick={handleOpenModal} />
            {
              showModal ?
                <div className="sidemodal" onClick={handleCloseModal}>
                  <div className="sidemodal-content">
                    {
                      userData ?

                        <div className='ac-banner'>
                          <div className='banner-overley'>
                            <div className='user'>
                              <div className='user-box'>
                                <img src={User} />
                              </div>
                              <div className='user-detail'>
                                <h4>{userData.name ? userData.name.split(' ')[0] : userData.phone}</h4>

                                <button onClick={logOutRootUser} >{loadLogout ? <>Logout </> : <BeatLoader color="#ffffff" size={10} />}</button>
                              </div>

                            </div>
                          </div>
                        </div>

                        // <div className='logout-btn'>
                        //   <button onClick={logOutRootUser}> {loadLogout ? <>Logout </> : <BeatLoader color="#ffffff" size={10} />}</button>
                        // </div> 

                        : <div className='cancelbtn'>

                        </div>
                    }

                    {
                      userData ? null : <div className='sidemenu-content'>
                        <p>Login to your account</p>
                        <button onClick={openPopups}>Login/Signup</button>
                      </div>
                    }
                    <div className='phmenu-div'>
                      <ul>
                        <Link to='/'><li className={isRouteActive('/') ? 'activenamekhf' : ''}>
                          <img src={Home} className='usericon' height='20px' width='20px' alt="" />
                          Home
                          <img src={Next} className='nexticon' height='10px' width='14px' alt="" />

                        </li></Link>
                        <Link to='/profile'><li className={isRouteActive('/profile') ? 'activenamekhf' : ''}>
                          <img src={usericon} className='usericon' height='20px' width='20px' alt="" />
                          Profile
                          <img src={Next} className='nexticon' height='10px' width='14px' alt="" />

                        </li></Link>
                        <Link to='/currentbooking'><li className={isRouteActive('/currentbooking') ? 'activenamekhf' : ''}>
                          <img src={Bookoing} className='usericon' height='20px' width='20px' alt="" />
                          Current bookings
                          <img src={Next} className='nexticon' height='10px' width='14px' alt="" />
                        </li></Link>
                        <Link to='/manageaddress'><li className={isRouteActive('/manageaddress') ? 'activenamekhf' : ''}>
                          <img src={Address} className='usericon' height='20px' width='20px' alt="" />
                          Manage address
                          <img src={Next} className='nexticon' height='10px' width='14px' alt="" />
                        </li></Link>
                        <Link to='/allorders'><li className={isRouteActive('/allorders') ? 'activenamekhf' : ''}>
                          <img src={Allorder} className='usericon' height='20px' width='20px' alt="" />
                          All orders
                          <img src={Next} className='nexticon' height='10px' width='14px' alt="" />
                        </li></Link>
                        {
                          userData ? <Link to='/gethelp'><li className={isRouteActive('/gethelp') ? 'activenamekhf' : ''}>
                            <img src={Help} className='usericon' height='20px' width='20px' alt="" />
                            Get help
                            <img src={Next} className='nexticon' height='10px' width='14px' alt="" />
                          </li></Link> :
                            <Link to='/help'><li className={isRouteActive('/help') ? 'activenamekhf' : ''}>
                              <img src={Help} className='usericon' height='20px' width='20px' alt="" />
                              Get help
                              <img src={Next} className='nexticon' height='10px' width='14px' alt="" />
                            </li></Link>
                        }
                        <Link to='/work-with-us'> <li>
                          <img src={Track} className='usericon' height='16px' width='16px' />
                          Register as a professional
                          <img src={Next} className='nexticon' height='14px' width='14px' />
                        </li></Link>
                        <Link to='/se_wallet'><li className={isRouteActive('/se_wallet') ? 'activenamekhf' : ''}>
                          <img src={Wallet} className='usericon' height='20px' width='20px' alt="" />
                          SalonEase Wallet
                          <img src={Next} className='nexticon' height='10px' width='14px' alt="" />
                        </li></Link>
                      </ul>




                      {/* {
                        userData ? <div className='logout-btn'>
                          <button onClick={logOutRootUser}> {loadLogout ? <>Logout </> : <BeatLoader color="#ffffff" size={10} />}</button>
                        </div> : null
                      } */}


                      <div className='popup-logo'>
                        <img src={Logo} width='170px' alt="" />
                        <p>Salon Ease Beauty & Personal care © All rights reserved</p>
                        <div className='media'>
                          <a href="https://www.instagram.com/salonease.in/">
                            <div className="popup-link">
                              <img src={Footer6} />
                            </div>
                          </a>
                          <a href="https://www.facebook.com/salonease.in/">
                            <div className="popup-link">
                              <img src={Footer7} className='media2' alt="" />
                            </div>
                          </a>
                          <a href="https://wa.me/9117770107?text=Hello,+I+am+interested+in+services/">
                            <div className="popup-link">
                              <img src={Footer8} className='media2' alt="" />
                            </div>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                : null
            }         
          </div>
          <div className="mobile-tagline ">


            <NavLink to="/">
              <img src={Logo} width='146px' height='29px' alt="" />
            </NavLink>
          </div>

          {/* <SearchLocation isOpen={isPopupOpen} onClose={closePopup} /> */}
          <div className="mobile_nav_link">
            <div className="mobile_link">
              {
                userData ? <Link className="navs_link" to="/gethelp">Get Help</Link> : <Link className="navs_link" to="/help">Get Help</Link>
              }
            </div>
          </div>
        </div>
      </div>



    </>
  )
}
