import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import './profile_header.css'
import LoginModal from '../components/login/loginModal'
import Logo from '../assets/images/SElogo.png'
import Search from '../assets/images/srch.png'
import Arrowup from '../assets/images/arrow-up.png'
import back from '../assets/images/arrow-left.png'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { serverAPI } from "..";
function ServiceNavBar() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
        localStorage.setItem("uuid", JSON.stringify(userData._id));
      } else {
        localStorage.removeItem("uuid");
        localStorage.removeItem("subNo");
        localStorage.removeItem("subService");
      }
    } catch (err) {
      localStorage.removeItem("uuid");
      localStorage.removeItem("subNo");
      localStorage.removeItem("subService");
    }
  };
  useEffect(() => {
    getRootUser();

  }, []);

  useEffect(() => {
    const userUui = JSON.parse(localStorage.getItem("uuid"));
    if (userUui) {
      setPageLoaded(true);
    } else {
      localStorage.removeItem("uuid");
    }
  }, [userData, serverAPI]);

  const location = useLocation(); // Get the current location
  // Define a function to check if a route is active
  const isRouteActive = (route) => location.pathname === route;


  const [click, setClick] = useState(false);
  const cartItemsCount = useSelector((state) => state.cart.products.length);

  const handleClick = () => setClick(!click);


  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // login popup

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [openMenu, setOpenMenu] = useState(false);
  const loginMenuRef = useRef(null);

  // Function to handle the click event outside of the "Login-menu"
  const handleClickOutside = (event) => {
    if (openMenu && loginMenuRef.current && !loginMenuRef.current.contains(event.target)) {
      // Check if the click event did not originate from the "My Account" element
      if (event.target.classList.contains("nav-links")) {
        return;
      }
      setOpenMenu(false);
    }
  };

  const [isPopupOpens, setIsPopupOpens] = useState(false);

  const openPopups = () => {
    setIsPopupOpens(true);
  };

  const closePopups = () => {
    setIsPopupOpens(false);
  };

  useEffect(() => {
    if (openMenu) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };




  }, [openMenu]);

  const [loadLogout, setLoadLogOut] = useState(true);

  const logOutRootUser = async () => {
    setLoadLogOut(false)
    try {
      const res = await axios.get(`${serverAPI}/auth/logout`, {
        withCredentials: true,
      });

      if (res.status === 200) {
        toast.success("Logout Succesfully 😃!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setTimeout(() => {
          setLoadLogOut(true)
          navigate('/')
        }, 2500);
      }
    } catch (err) {
    }
  };

  return (
    <>
      <nav className="navbar Navbar ">
        <div className="nav-Container">
          <NavLink to='/' className="nav-logo">
            <div className="logo_img"><img src={Logo} alt="desktop_logo" /></div>
            <p className="tageline2" >| </p>
            <p className="tageline">Salon service at home</p>

          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-items" >
              <NavLink
                exact
                activeClassName="active"
                className="nav-links"
                to='/work-with-us'
              >
                Register as a professional
              </NavLink>
            </li>
            {pageLoaded ?
              <li className="nav-items" onClick={() => setOpenMenu(!openMenu)} >
                <a
                  activeClassName="active"
                  className="nav-links"

                >My Account</a>

              </li>
              :
              <li className="nav-items" onClick={openPopups} >
                Login/Signup
              </li>
            }
            <LoginModal isOpen={isPopupOpens} onClose={closePopups} />


            <li className="nav-items" >
              <NavLink
                exact
                to="/help"
                activeClassName="active"
                className="nav-links"

              >
                Help
              </NavLink>
            </li>


            {/* <li className="nav-items"  style={{ marginLeft: '-18px' }}>
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Blogs
              </NavLink>
            </li>
            <li>
              <div className="cart-icon" style={{ marginTop: "-3px" }}>
              
                <img className="icon" src={Cart} alt="cart_icon" />
              </div>
            </li> */}

          </ul>
          {openMenu ?
            <div className="Login-menu" ref={loginMenuRef}>
              <img src={Arrowup} width='15px' className="arrowup" alt="" />
              <ul>
                <li style={{ paddingTop: '20px', height: '50px' }}>{userData ? userData?.phone : ''}</li>
                <NavLink to='/profile'><li>Go to my account</li></NavLink>
                <NavLink to='/cart'><li>Cart</li></NavLink>
                <li onClick={logOutRootUser}>Logout</li>
              </ul>

            </div>
            : null}


          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>

        </div>
      </nav>

      <div className="mobile_active">
        <div className="mobile_nav">
          <div className="back-nav">
            <table style={{ width: '90%' }} >
              <tr>
                <th onClick={() => { navigate('/') }}> <img src={back} width="20px" alt="" /></th>

                <Link to="/searpage">
                  <td style={{ float: 'right' }}><img src={Search} width="20px" alt="" /></td>
                </Link>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </>
  );
}

export default ServiceNavBar;