import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import Header from '../../layouts/header'
// import Carousel from 'react-multi-carousel'

import 'react-multi-carousel/lib/styles.css'
import House from '../../assets/images/house.png'
// import Stars from '../../assets/images/stars.png'
// import imag1 from '../../assets/images/1.png'
import Pen from '../../assets/images/pen.png'
import clock from '../../assets/images/clock.png'
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import HashLoader from "react-spinners/HashLoader";
import PropagateLoader from "react-spinners/PropagateLoader"
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
import { setOrderSlot, setOrderUser } from '../../utils/orderSlice';
import { selectTotalCost, selectTotalTime } from "../../utils/cartSlice";
import { DateTime } from 'luxon';
import cancel from '../../assets/images/cancel.png';
import Swal from 'sweetalert2';



export default function Reschedulepage({ onClose, appointment }) {
    const currentDates = new Date();
    const month = currentDates.toLocaleString('default', { month: 'long' });
    const year = currentDates.getFullYear();
    const [currentDate, setCurrentDate] = useState(DateTime.now());

    const { userData } = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [islodings, setIsLodings] = useState(true);
    const [isConfirm, setIsConfrim] = useState(true)
    const [hidden, setHidden] = useState(false);


    const [slot, setSlot] = useState()
    const [availableDates, setAvailableDates] = useState([]);
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [rowss, setRowss] = useState(true);
    const addressData = useSelector((state) => state.order);
    const totalCost = useSelector(selectTotalCost);
    const totalTime = useSelector(selectTotalTime);
    const [activeDate, setActiveDate] = useState(null);


    const handleDateClick = (clickedDate) => {
        setActiveDate(clickedDate.date); // Set the clicked date as active
        // Define the URL for the second GET request based on the clicked date
        setSlot('')
        setAvailableTimeSlots([])

        const secondApiUrl = `${serverAPI}/available-time-slots/${clickedDate.date}`;

        // Use Axios to make the second GET request
        axios.get(secondApiUrl)
            .then((response) => {
                // Handle the second GET request's successful response here
                setAvailableTimeSlots(response.data);
                setRowss(true)
                if (response.data.message) {
                    setRowss(false);
                }
            })
            .catch((err) => {
                // Handle errors for the second request
            });
    };

    const handleDateandTimeClick = (clickedTime) => {
        setSlot({
            ...slot,
            date: activeDate,
            time: clickedTime,
        })
    };

    const clicktonext = async () => {
        setIsConfrim(false);
        if (slot?.date || slot?.time) {
            try {
                const result = await Swal.fire({
                    title: 'Confirm Reschedule',
                    text: 'Are you sure want to reschedule your appointment?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#981313',
                    cancelButtonColor: '#38AE36',
                    confirmButtonText: 'Yes, reschedule it!',
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });

                if (result.isConfirmed) {
                    try {
                        const res = await axios.put(
                            `${serverAPI}/update-appointment/${appointment.orderID}`,
                            { date: slot.date, time: slot.time },
                            { withCredentials: true }
                        );
                        if (res.status === 200) {
                            Swal.fire('Reschedule!', 'The appointment has been rescheduled.', 'success');
                            setTimeout(() => {
                                setIsConfrim(true);
                                window.location.reload();
                            }, 1990);
                        } else {
                            Swal.fire('Error', 'Failed to reschedule the appointment.', 'error');
                            setIsConfrim(true);
                        }
                    } catch (error) {
                        setIsConfrim(true);
                        Swal.fire('Error', 'Failed to reschedule the appointment.', 'error');
                    }
                } else {
                    setIsConfrim(true);
                }
                Swal.hideLoading();
            } catch (error) {
                setIsConfrim(true);
                Swal.fire('Error', 'Failed to reschedule the appointment.', 'error');
            }
        } else {
            toast.error("Please select date & time both!", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setIsConfrim(true);
        }
    };



    useEffect(() => {
        // Define the URL for the first GET request
        const apiUrl = `${serverAPI}/available-dates`;

        // Use Axios to make the first GET request
        axios.get(apiUrl)
            .then((response) => {
                // Handle successful response here
                setAvailableDates(response.data);
                getTime();
            })

            .catch((err) => {
                // Handle errors for both requests
            });


    }, [addressData.slot]);




    useEffect(() => {
        // Update the current date and time every minute
        const interval = setInterval(() => {
            setCurrentDate(DateTime.now());
        }, 60000); // 60,000 milliseconds = 1 minute

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Check if the current time is within the specified frame
    const isWithinTimeFrame = currentDate.hour >= 0 && currentDate.hour < 18;

    // Calculate the next day's date
    const nextDayDate = currentDate.plus({ days: 1 });


    // Format the date for display

    const formattedDate = isWithinTimeFrame
        ? currentDate.toFormat('yyyy-MM-dd')
        : nextDayDate.toFormat('yyyy-MM-dd');

    const getTime = () => {
        if (Object.keys(addressData.slot).length > 0) {
            const data = []
            data.push({
                date: addressData.slot.date,
                time: addressData.slot.time,
            })
            const convertedData = data.reduce((result, item, index) => {
                result[index] = item;
                return result;
            }, {});
            const finalData = convertedData[0];
            const secondApiUrl = `${serverAPI}/available-time-slots/${addressData.slot.date}`;
            // Define the URL for the second GET request based on the clicked date
            setActiveDate(formattedDate);
            // Use Axios to make the second GET request
            axios.get(secondApiUrl)
                .then((response) => {


                    // Handle the second GET request's successful response here
                    setAvailableTimeSlots(response.data);
                    setSlot(finalData)
                    setRowss(true)
                    if (response.data.message) {
                        setRowss(false);
                    }
                })
                .catch((err) => {
                    // Handle errors for the second request
                });
            setActiveDate(addressData.slot.date);
        } else {
            // Define the URL for the second GET request based on the first date  2023-09-17
            const secondApiUrl = `${serverAPI}/available-time-slots/${formattedDate}`;
            // Define the URL for the second GET request based on the clicked date
            setActiveDate(formattedDate);
            // Use Axios to make the second GET request
            axios.get(secondApiUrl)
                .then((response) => {

                    // Handle the second GET request's successful response here
                    setAvailableTimeSlots(response.data);

                    setRowss(true)
                    if (response.data.message) {
                        setRowss(false);
                    }
                })
                .catch((err) => {
                    // Handle errors for the second request
                });
        }

    }

    return (
        <>
            <div className='reschdule-wrap' style={{ marginTop: "15px" }}>
                <div className='res-heading' style={{ paddingBottom: "5px" }}>
                    <h4 style={{ padding: '0px 10px' }}> <img src={clock} width='20px' style={{ marginRight: '5px', marginTop: '-3px' }} alt='' /> Choose date and time slot</h4>
                </div>
                <div className='date'>
                    <h5>Choose date</h5>
                </div>
                <div className='time-box'>
                    <h5>{month}, {year}</h5>
                    <div className='scroll-wrap'>
                        <div className='my-2 time-box_scroll'>
                            {availableDates?.map((date, index) => (
                                <div className={`${index === 0 ? ' timebox1' : 'timebox1 marginleft'}  ${activeDate === date.date ? 'timebox1-active' : ''}`} key={index} style={{ cursor: "pointer" }} onClick={(e) => handleDateClick(date)}>
                                    <p>{date.dayOfWeek.slice(0, 3)}</p>
                                    <h3>{date.date.slice(-2)}</h3>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                <div className='choose-time-slot'>
                    <p>Choose time slot</p>
                    <div className='time-boxes'>
                        <div className="row time-row mob-row" >
                            {
                                availableTimeSlots.length > 0 ?
                                    <>
                                        {availableTimeSlots?.map((slots, slotIndex) => (
                                            <div className={`col-md-3 col-4`} style={{ display: slots.isAvailable ? "block" : "none" }} key={slotIndex}>
                                                {appointment.date === activeDate &&
                                                    appointment.time === slots.time ? (
                                                    <div className='slotfull' style={{ cursor: 'not-allowed' }}
                                                    onClick={() => appointment.date !== activeDate ||
                                                        appointment.time !== slots.time
                                                        ? handleDateandTimeClick(slots.time)
                                                        : toast.warn(
                                                            'Current appointment is already book for same slot!',
                                                            {
                                                                position: 'top-center',
                                                                autoClose: 2500,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            }
                                                        )}
                                                    >
                                                        <span>Current Booking </span>
                                                        <h6>{slots.time}</h6>
                                                    </div>
                                                ) :
                                                    <>
                                                        {
                                                            slots.maxBookingsNotFull ? (
                                                                <div className={`
                                                                    ${slotIndex === 0 ? 'time-box1' : 'time-box1'}
                                                                    ${slots.time === slot?.time ? 'timebox1-active' : ''}
                                                                    ${appointment.date === activeDate && appointment.time === slots.time ? 'disabled-time-slot' : ''}`} onClick={() => appointment.date !== activeDate ||
                                                                        appointment.time !== slots.time
                                                                        ? handleDateandTimeClick(slots.time)
                                                                        : toast.error(
                                                                            'This time slot is disabled',
                                                                            {
                                                                                position: 'top-center',
                                                                                autoClose: 2500,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true,
                                                                                progress: undefined,
                                                                            }
                                                                        )}

                                                                    style={{
                                                                        cursor:
                                                                            appointment.date !== activeDate ||
                                                                                appointment.time !== slots.time
                                                                                ? 'pointer'
                                                                                : 'not-allowed',
                                                                    }}>
                                                                    {slots.time}
                                                                </div>
                                                            ) : (
                                                                <div className='slotfull' style={{ cursor: "not-allowed" }}>
                                                                    <span>Slot Full</span>
                                                                    <h6>{slots.time}</h6>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "35vh" }}>
                                        <BeatLoader color="#93294B" size={10} />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='btn-grp1'>
                    <div className='curnt-btn'>
                        <button onClick={() => { onClose() }}>Close</button>
                        <button onClick={() => { clicktonext() }}>{isConfirm ? <>Confirm Reschedule</> : <BeatLoader color="#ffffff" size={10} />}</button>
                    </div>
                    {/* onClick={openPopup} <Reschedule isOpen={isPopupOpen} onClose={closePopup} /> */}

                </div>
            </div>

        </>
    )
}
