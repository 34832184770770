import React, { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import './newquestion.css';

function NewQuestion() {

    const data = [
        {
            id: 1,
            question: 'What is Salon Ease?',
            answer: 'Salon Ease is a home salon service provider that allows users to search for and book salon services through its platform. It brings professional salon services to your doorstep, allowing you to enjoy a spa-like experience in the comfort of your own home.'

        },
        {
            id: 2,
            question: 'How can I book services with Salon Ease?',
            answer:
                'SalonEase offers a comprehensive range of beauty services, including haircuts, styling, coloring, skincare, facials, manicures, pedicures, makeup, waxing, and more. Please visit our Services page for a detailed list.'

        },
        {
            id: 3,
            question: 'What services does Salon Ease offer?',
            answer:
                'Salon Ease offers a wide range of salon services including haircuts, styling, coloring, manicures, pedicures, facials, massages, and more. You can find a comprehensive list of services on our platform.'

        },
        {
            id: 4,
            question: 'Can I choose my preferred stylist or beautician?',
            answer:
                'Yes, you have the option to select a specific stylist or beautician based on their availability.'

        },
        {
            id: 5,
            question: 'How do I pay for the services?',
            answer:
                'You can make payments for the services directly through the app or in cash after service. Salon Ease accepts various payment methods including credit/debit cards, digital wallets, and other online payment options.'

        },
        {
            id: 6,
            question: 'Is there a cancellation policy?',
            answer:
                'Yes, Salon Ease has a cancellation policy in place. You can cancel or reschedule your appointment up to 03 hours before the scheduled appointment without incurring any charges.'

        },
        {
            id: 7,
            question: 'Are there any additional charges for home service?',
            answer:
                'There is no additional or hidden charge for home services.'

        },
        {
            id: 8,
            question: 'Is Salon Ease available in my area?',
            answer:
                'Salon Ease is continuously expanding its service areas. To check if Salon Ease operates in your location, simply enter your address in our website and it will provide you with a list of available services.'

        },
        {
            id: 9,
            question: 'What safety measures are in place due to COVID-19?',
            answer:
                'Salon Ease follows strict hygiene and safety protocols to ensure the safety of both customers and service providers. This includes the use of personal protective equipment, regular sanitization, hygiene kit  and adherence to local health guidelines.'

        },
        {
            id: 10,
            question: 'How can I contact Salon Ease for support or inquiries?',
            answer:
                'For any support or inquiries, you can reach out to Salon Ease through the website help section feature or visit the "Contact Us" section on the website.'

        },
       
    ];

    const [showStates, setShowStates] = useState(Array(data.length).fill(false));

    const toggleAnswer = (index) => {
        setShowStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    return (
        <>
        <div className='newquestion_card'>
         
                <h4><span>Frequently</span> Asked Questions</h4>
           
            <div className='question_card_inner'>
                <div className='column'>
                    {data.map((item, index) => {
                        if (index % 2 === 0) {
                            return (
                                <div className='question_card_innerBox' key={item.id} onClick={() => toggleAnswer(index)}>
                                    <div className='question_card_flex'>
                                        <h3>{item.question}</h3>
                                        {showStates[index] ? (
                                            <IoIosArrowUp style={{ fontSize: '20px', fontWeight: '600', color: '#981313' }} />
                                        ) : (
                                            <IoIosArrowDown style={{ fontSize: '20px', fontWeight: '600', color: '#981313' }} />
                                        )}
                                    </div>
                                    {showStates[index] && <p style={{ paddingTop: '13px' }}>{item.answer}</p>}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
                <div className='column'>
                    {data.map((item, index) => {
                        if (index % 2 !== 0) {
                            return (
                                <div className='question_card_innerBox' key={item.id} onClick={() => toggleAnswer(index)}>
                                    <div className='question_card_flex'>
                                        <h3>{item.question}</h3>
                                        {showStates[index] ? (
                                            <IoIosArrowUp style={{ fontSize: '20px', fontWeight: '600', color: '#981313' }} />
                                        ) : (
                                            <IoIosArrowDown style={{ fontSize: '20px', fontWeight: '600', color: '#981313' }} />
                                        )}
                                    </div>
                                    {showStates[index] && <p style={{ paddingTop: '13px' }}>{item.answer}</p>}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
        </>
    );
}

export default NewQuestion;
