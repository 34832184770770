
import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import mobile1 from '../../assets/BannerHomePageTop/re1.png'
import mobile2 from '../../assets/BannerHomePageTop/re2.png'
import mobile3 from '../../assets/BannerHomePageTop/re3.png'
import mobile4 from '../../assets/BannerHomePageTop/re4.png'
import mobile5 from '../../assets/BannerHomePageTop/re5.png'
import mobile6 from '../../assets/BannerHomePageTop/re6.png'
import mobile7 from '../../assets/BannerHomePageTop/re7.png'
import { serverAPI } from "../..";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";

export default function Mobilebanner() {
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const [addkey, setAddKey] = useState(null);

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('addkey'));
        if (items) {
            setAddKey(items);
        }
    }, []);

    const [searchInput, setSearchInput] = useState('');
    const [services, setServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);

    useEffect(() => {
        // Replace 'your-api-endpoint' with the actual API endpoint that serves your services data.
        axios.get(`${serverAPI}/services`)
            .then((response) => {
                setServices(response.data);
            })
            .catch((error) => {
                console.error('Error fetching services:', error);
            });
    }, []);

    useEffect(() => {
        const filtered = services.filter((service) =>
            service.service_name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredServices(filtered);
    }, [searchInput, services]);

    const handleServiceClick = (service) => {
        // Construct the URL with the required parameters
        const url = `/service?name=${encodeURIComponent(service.category)}&id=${service.categoryId}`;

        // Navigate to the specified URL
        navigate(url);
    }
    return (
        <>
            <div className="mobile-banner">
                <Slider {...settings}>
                    
                    <div className="slide-img">
                        <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864&service=Any%204%20%20services%20%40999%20'>
                            <img src={mobile2} alt="" />
                        </Link>
                    </div>
                    <div className="slide-img">
                        <Link to='/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788&service=Salon%20Ease%20Exclusive%20Packages'>
                            <img src={mobile3} alt="" />
                        </Link>
                    </div>
                    <div className="slide-img">
                        <Link to='/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788'>
                            <img src={mobile4} alt="" />
                        </Link>
                    </div >
                    <div className="slide-img">
                        <Link to='/service?name=Create%20your%20own%20package&id=651859f0909e90359c9a4864&service=Any%205%20Service%20%401649'>
                            <img src={mobile5} alt="" />
                        </Link>
                    </div>
                    <div className="slide-img">
                        <Link to='/service'>
                            <img src={mobile6} alt="" />
                        </Link>
                    </div>
                    <div className="slide-img">
                        <Link to='/service?name=Waxing&id=651be151909e90359c9a5c8f&service=Rica%20Waxology' >
                            <img src={mobile7} alt="" />
                        </Link>
                    </div>

                </Slider>
            </div>
        </>
    )
}
