import React, { useState } from 'react';
import axios from 'axios';
import { serverAPI } from '..';


export default function PayUmoney() {
    const [amount, setAmount] = useState('');
    const [productName, setProductName] = useState('');
    const [userEmail, setUserEmail] = useState('');
  
    const handlePayment = async () => {
      try {
        // Make a POST request to your Node.js server to initiate the payment
        const response = await axios.post(`${serverAPI}/payment/create-payment`, {
          amount,
          productName,
          userEmail,
        },{
          withCredentials: true, // Include credentials (cookies)
      });
        console.log(response);
  
        // Redirect to the PayUmoney payment page
        window.location.href = response.data.paymentLink;
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
      <div>
        <h1>Pay with PayUmoney</h1>
        <div>
          <input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="User Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        <button onClick={handlePayment}>Pay</button>
      </div>
    )
}
