import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import ServiceHead from '../layouts/serviceHead';
import Servicelist from '../components/booking/servicelist';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { serverAPI } from '..';

export default function Booking() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceName = queryParams.get('name');
  const catId = queryParams.get('id');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(`${serverAPI}/categories`)
      .then(response => {
        const filteredCategories = response.data.filter(category => category._id === catId);
          setCategories(filteredCategories.length > 0 ? filteredCategories : []);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, [catId]); 
  
  return (
    <>
      <Helmet>
        <title>{serviceName} | Salon Ease</title>
        {categories.length > 0 && (
          <meta name="keywords" content={`Salon, Ease, ${categories[0].name}`} />
        )}
      </Helmet>
      <ServiceHead />
      <Servicelist />
    </>
  )
}
