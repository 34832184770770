import React, { useState, useEffect } from 'react'
// import Slider from './silder'
import './newhome.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Next from '../../assets/images/arrow-down.png'
import Location from '../../assets/images/location.png'
import CardSlider from './cardslider';
import Testimonial from './testimonial';
import Choose1 from '../../assets/images/choose1.png'
import Choose2 from '../../assets/images/choose2.png'
import Choose3 from '../../assets/images/choose3.png'
import Choose4 from '../../assets/images/choose4.png'
import Choose5 from '../../assets/images/choose5.png'
import Choose6 from '../../assets/images/choose6.png'
import Brand1 from '../../assets/images/brand1.png'
import Brand2 from '../../assets/images/brand2.png'
import Brand3 from '../../assets/images/brand3.png'
import Brand4 from '../../assets/images/brand4.png'
import Brand5 from '../../assets/images/brand5.png'
import Brand6 from '../../assets/images/brand6.png'
import NewQuestion from './accordion/newquestion';
import Bannerimage from './bannerimage';
import SearchPage from "./searchpage";
import SearchLocation from "./searchlocation";
import axios from 'axios';
import { serverAPI } from '../..';
import { Link, useNavigate } from 'react-router-dom';
import Mobilebanner from './mobilebanner';




export default function NewHome() {
  const navigate = useNavigate();

  const [review, setResview] = useState([])
  const [categories, setCategories] = useState([]);
  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/getReviewsDetails`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        setResview(res.data.result.reviews)
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    getRootUser();
    axios.get(`${serverAPI}/categories`)
      .then(response => {
        // Assuming the response data is in the format you provided
        setCategories(response.data);

      })
      .catch(error => {
        setCategories([]);
        console.error('Error fetching categories:', error);
      });

  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // loacation pop up

  const [isPopupLocOpen, setIsPopupLocOpen] = useState(false);

  const openLocPopup = () => {
    setIsPopupLocOpen(true);
  };

  const closeLocPopup = () => {
    setIsPopupLocOpen(false);
  };

  const handleServiceClick = (item) => {
    // Construct the URL with the required parameters
    const url = `/service?name=${encodeURIComponent(item.name)}&id=${item._id}`;

    // Navigate to the specified URL
    navigate(url);
  }
  const [addkey, setAddKey] = useState(null);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('addkey'));
    if (items) {
      setAddKey(items);
    }
  }, []);


  return (
    <> 
        <div className='frontpage' >
          <div className='home-slider'>
              <div className="mobile-taglin dnon "  style={{height : '0'}}>
                {/* <img src={Location} width='16.21px' height="16.21px" style={{ marginRight: '5px' }} alt='' />
                {
                  addkey ? <p className="mobile_tageline1">{addkey}</p> : <p className="mobile_tageline1">Add Your Location...</p>
                }
                <img src={Next} width='12px' height="auto" style={{ marginLeft: '15px' }} alt='' /> */}
              </div>

            {/*onClick={openLocPopup}  <SearchLocation isOpen={isPopupLocOpen} onClose={closeLocPopup} /> */}
            <div className='displnon'>
              <Link to="/searpage">
                <div className="search-container" >
                  <input type="text" placeholder="Search for services" className="search-input" />
                  <button className="search-button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </Link>
              {/* onClick={openPopup} <SearchPage isOpen={isPopupOpen} onClose={closePopup} /> */}
            </div>

            <div className='home-slid'>
              <Bannerimage />
            </div>

            <div className='mobile-bnr'>
              <Mobilebanner />
            </div>

          </div>

          <div className='card-container'>
            <h3> <span>Shop by</span> category</h3>
            <div class="grid-container martop" >
              {
                categories !== null ? <>{categories?.map((item, index) => (
                  <div class="grid-item" key={index}>
                    <div class="Service-boxx" onClick={() => { handleServiceClick(item) }} style={{ cursor: "pointer" }}>
                      <div className='box-img'><img src={item?.image} alt={item?.name} /></div>
                      <p>{item?.name}</p>
                    </div>
                  </div> 
                ))}</> : null
              }

            </div>





          </div>

          <div className='selling'>
            <CardSlider />
          </div>

          {/* <div className='testmonial'>
            <h2>Testimonials</h2>
            <div className='testmonial-card-wrap'>
              <Testimonial reviews={review} /></div>

          </div> */}

          <div className='why-choose'>
            <h2>Why choose <span>Salon Ease ?</span> </h2>
            <div className='choose-img2'>
              <div className='choose1'>
                <div className='choose-img' ><img src={ Choose2} alt='' /></div>
                <h4>Trained, Verified Beauticians</h4>
              </div>
              <div className='choose1'>
                <div className='choose-img'><img src={ Choose3} alt='' /></div>
                <h4>Schedule Services Easily & always on time</h4>
              </div>
              <div className='choose1'>
                <div className='choose-img'><img src={ Choose6} alt='' /></div>
                <h4>Safe and Hygenic</h4>
              </div>
            </div>
            <div className='choose-img2' >
              <div className='choose1'>
                <div className='choose-img'><img src={Choose5} alt='' /></div>
                <h4>Best Brands in 1-Time Use Packs</h4>
              </div>
              <div className='choose1'>
                <div className='choose-img'><img src={ Choose1} alt='' /></div>
                <h4>Mess Free Service</h4>
              </div>
              <div className='choose1'>
                <div className='choose-img'><img src={ Choose4} alt='' /></div>
                <h4>Best Home Salon Services</h4>
              </div>
            </div>
          </div>

          <div className='Brand-sec'>
            <h2>Brands we use <span>in one time pack</span> </h2>
            <div className='brand-box'>
              <div className='choose-img1'>
                <div className='choose1'>
                  <div className='brand1' ><img src={Brand4} alt='' /></div>

                </div>
                <div className='choose1'>
                  <div className='brand2' ><img src={Brand2} alt='' /></div>

                </div>
                <div className='choose1'>
                  <div className='brand3' ><img src={Brand1} alt='' /></div>

                </div>
              </div>
              <div className='choose-img1' style={{ marginTop: '59px' }}>
                <div className='choose1'>
                  <div className='brand4' ><img src={Brand3} alt='' /></div>

                </div>
                <div className='choose1'>
                  <div className='brand5' ><img src={Brand6} alt='' /></div>

                </div>
                <div className='choose1'>
                  <div className='brand6' ><img src={Brand5} alt='' /></div>

                </div>
              </div>
            </div>

            <div className='question'>
              <NewQuestion />
            </div>
          </div>

        </div>
   
    </>
  )
}
