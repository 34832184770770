import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Rating from '../../assets/images/rating.png'
import Track from '../../assets/images/Maskgroup6.png'
import orderimg from '../../assets/images/v682_250.png'
import Clock from '../../assets/images/clock.png'
import Reschedule from './reschedule'
import Reschedulepage from './reschedulepage'
import Swal from 'sweetalert2';
import axios from 'axios';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";

export default function CartComp({ appointment, index, callnagigate }) {
    const isCurrentDate = () => {
        const currentDate = new Date().toISOString().split('T')[0];
        return currentDate === appointment.date;
    };
    const navigate = useNavigate();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isConfirm, setIsConfrim] = useState(true)

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    // Function to convert time in the format "hh h mm min" to minutes
    const convertTimeToMinutes = (time) => {
        const [hours, minutes] = time.split("h");
        return parseInt(hours) * 60 + parseInt(minutes);
    };

    let totalMinutes = 0;

    // Iterate through each order and each item in the cart and add up the times

    appointment.cart.forEach((item) => {
        const time = item.time;
        totalMinutes += convertTimeToMinutes(time);
    });


    // Convert the total minutes back to "h hours m minutes" format
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const totalTime = hours + " h " + minutes + " min";


    let combinedString = '';


    appointment.cart.forEach(item => {
        if (item.pre_requirements !== 'na') {
            combinedString += item.pre_requirements + ', ';
        }
    });


    const requiredItemsArray = combinedString.split(', ').filter(item => item.length > 0);


    const clicktonext = async (date, time, orderID) => {
        setIsConfrim(false);
        try {
            const result = await Swal.fire({
                title: 'Confirm Canceled',
                text: 'Are you sure want to Cancel your appointment?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#981313',
                cancelButtonColor: '#38AE36',
                confirmButtonText: 'Yes, Cancel it!',
                cancelButtonText: `Don't Cancel`,
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(
                        `${serverAPI}/cancel-appointment/${appointment.date}/${appointment.time}/${appointment.orderID}`,
                        { withCredentials: true }
                    );
                    if (res.status === 200) {
                        Swal.fire('Cancel!', 'The appointment has been Cancel.', 'success');
                        setTimeout(() => {
                            setIsConfrim(true);
                            window.location.reload();
                        }, 1990);
                    } else {
                        Swal.fire('Error', 'Failed to Cancel the appointment.', 'error');
                        setIsConfrim(true);
                    }
                } catch (error) {
                    setIsConfrim(true);
                    Swal.fire('Error', 'Failed to Cancel the appointment.', 'error');
                }
            } else {
                setIsConfrim(true);
            }
            Swal.hideLoading();
        } catch (error) {
            setIsConfrim(true);
            Swal.fire('Error', 'Failed to Cancel the appointment.', 'error');
        }

    };

    const showSuccessPopup = async () => {
        const results = await Swal.fire({
            title: "Cannot cancel this booking!",
            text: `Beautician assigned for your service Please contact customer for further assistance.`,
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#981313",
            confirmButtonText: "Call now",
            cancelButtonText: "No don't cancel"
        });
        if (results.isConfirmed) {
            makePhoneCall();
        }

    };

    const showSuccessPopups = async () => {
        const results = await Swal.fire({
            title: "Cannot reschedule this booking!",
            text: `Beautician assigned for your service Please contact customer for further assistance.`,
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#981313",
            confirmButtonText: "Call now",
            cancelButtonText: "No don't reschedule"
        });
        if (results.isConfirmed) {
            makePhoneCall();
        }

    };

    function makePhoneCall() {
        window.location.href = "tel:+919117770107"; // Replace +123456789 with the desired phone number
    }

    const location = useLocation();
    const searchParams = new URLSearchParams(decodeURIComponent(location.search));
    // Access the current pathname
    const currentPath = location.pathname;
    const transactionId = searchParams.get('transactionId');
    const statusC = searchParams.get('statusC');
    const appointmentId = searchParams.get('appointmentId');
    const [islodings, setIsLodings] = useState(true);

    const status = {
        failure: "rzp_test_LyQl8cyV8Y1ACw",
        success: "MEUwA5dXwpQMcLggdReCQr7O",
    };

    useEffect(() => {
        setTimeout(() => {
            if (statusC && transactionId) {
                onlineTosty()
            }
        }, 2000);
    }, [])

    const onlineTosty = async () => {
        if (statusC === status.success) {
            if (appointmentId) {
                onOnlineClick(appointmentId);
            }
            // onlineCheckoutHandel();
        } else if (statusC === status.failure) {
            const resultss = Swal.fire({
                title: "Payment Failed",
                text: `There was an error placing your order!`,
                icon: "error",
                allowOutsideClick: false,
                showCloseButton: true,
            });
            if (resultss.isConfirmed) {
                navigate('/currentbooking');
            } else {
                navigate('/currentbooking');
            }
        }
    }

    const handelOnlinePayment = async (appointment) => {
        setIsLodings(false); // Set loading state to true if the user cancels the action

        const data = {
            name: appointment?.user?.name,
            amount: appointment?.paymentDetails?.payableAmount,
            number: appointment?.user?.phone,
            MUID: "SEID" + Date.now(),
            transactionId: 'SETID' + Date.now(),
            nowurl: currentPath,
            appointmentId: appointment._id,
        }

        const confirmation = await Swal.fire({
            title: "Confirmation",
            text: `Are you sure about to Payment Rs. ${parseFloat(appointment?.paymentDetails?.payableAmount).toFixed(2)}?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#38AE36",
            cancelButtonColor: "#981313",
            confirmButtonText: "Yes, proceed",
            cancelButtonText: "No, cancel",
        });

        if (confirmation.isConfirmed) {
            axios.post(`${serverAPI}/online/paymentUser`, { ...data }).then(res => {
                const redirectUrl = res.data.data.instrumentResponse.redirectInfo.url;
                setTimeout(() => {
                    // Create a hidden anchor element
                    var a = document.createElement('a');
                    a.href = redirectUrl;
                    a.target = '_blank';

                    // Append the anchor element to the document and simulate a click
                    document.body.appendChild(a);
                    a.click();

                    // Remove the anchor element from the document
                    document.body.removeChild(a);
                    setIsLodings(true); // Set loading state to true if the user cancels the action
                }, 1500);
            })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setIsLodings(true); // Set loading state to true if the user cancels the action
        }
    }

    const onOnlineClick = async (appointmentId) => {
        await axios
            .post(`${serverAPI}/beautician/online/${appointmentId}`,)
            .then((response) => {
                if (response.status === 200) {
                    const resultss = Swal.fire({
                        title: "Payment Done",
                        text: `The successful online payment for this appointment has been received!`,
                        icon: "success",
                        allowOutsideClick: false,
                        showCloseButton: true,
                    });
                    if (resultss.isConfirmed) {
                        navigate('/currentbooking');
                    } else {
                        navigate('/currentbooking');
                    }
                }
            })
            .catch((error) => {
                console.error("Error updating order status:", error);
                toast.error("Payment done but Something is worng!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            });
    }


    return (
        <>
            <div style={{ marginTop: index === 0 ? '0' : '5rem' }} key={index}>

                <div className='booking-card'>
                    <div className='card-head'>
                        <p className='parah1'>Booking no. - {appointment.orderID}</p>
                        <p className='parah2'>Slot - {appointment.date + ", " + appointment.time}</p>
                    </div>

                    {appointment.cart.map((product, index) => (
                        <>
                            <div className='current-body' key={index}>
                                <div className='ordr-dtl'>
                                    <div className="odr-img">
                                        <img src={product.image} alt={product.service_name} />
                                    </div>
                                    <div className='ord-text'>
                                        <div className='ord_details '>
                                            <div className='ord_box1'>
                                                <h4>{product.service_name}</h4>

                                                {
                                                    product?.subNo > 0 ?
                                                        <>
                                                            <ul>
                                                                {appointment.subService
                                                                    .filter(item => item.checked === true)
                                                                    .map((item, index) => (
                                                                        <li key={index}>{item.name}</li>
                                                                    ))}
                                                            </ul>

                                                        </> : null
                                                }
                                            </div>

                                            <div className='odr-multiple'>
                                                <h6> × {product.quantity}</h6>
                                            </div>

                                        </div>

                                        <div className='ord-rate'>
                                            <div className='rate1'>
                                                <h5>₹ {parseInt(product.price)}/-</h5>
                                            </div>
                                            <div className='rate2'>
                                                <h4><img src={Clock} height='15px' width='15px' style={{ marginTop: '-3px', marginRight: '5px' }} alt='' />{product.time}</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    ))}
                    <div className='current-ftur' style={{ marginBottom: "30px" }} >
                        <div className='futr1' >
                            {
                                requiredItemsArray.length > 0 ? <>
                                    <h4>Things need to be ready before service</h4>
                                    <ul>
                                        {requiredItemsArray.map((item, index) => (
                                            <li key={index}> {item}</li>
                                        ))}
                                    </ul>
                                </> : null
                            }

                        </div>
                        <div className='futr2'>
                            <div className='futr2_5'>
                                <h4 style={{ cursor: "pointer" }} onClick={() => { callnagigate(appointment._id) }}><img src={Track} className='usericon' height='30px' width='30px' alt='' />TRACK YOUR BOOKING</h4>
                                <p>Total service time : {totalTime}</p>


                                <div className='btn-grp1'>
                                    <div className='curnt-btn'>

                                        {
                                            appointment.beauticianAssigned === true ?
                                                <>
                                                    <button onClick={() => {
                                                        showSuccessPopup();
                                                    }}> {isConfirm ? <>CANCEL</> : <BeatLoader color="#ffffff" size={10} />}</button>
                                                    <>{
                                                        isPopupOpen ? null : <button onClick={() => {
                                                            showSuccessPopups();
                                                        }}>RESCHEDULE</button>
                                                    } </>
                                                </>

                                                :
                                                <>
                                                    <button onClick={() => {
                                                        clicktonext()
                                                    }}> {isConfirm ? <>CANCEL</> : <BeatLoader color="#ffffff" size={10} />}</button>
                                                    <>{
                                                        isPopupOpen ? null : <button onClick={() => {
                                                            openPopup()
                                                        }}>RESCHEDULE</button>
                                                    } </></>
                                        }



                                    </div>
                                    {
                                        appointment.beauticianAssigned ? <>{ 
                                            appointment?.beauticianDetails?.otpVerify === true ? null : <p style={{marginTop: '20px'}}>OTP to start your service is : <b>{appointment?.assignedOTP}</b></p>
                                        }</> : null
                                    }
                                    
                                    {/* onClick={openPopup} <Reschedule isOpen={isPopupOpen} onClose={closePopup} /> */}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    isPopupOpen ? <Reschedulepage onClose={closePopup} appointment={appointment} /> : null
                }
                <div className='current-pay'>

                    <h5>Payment summary</h5>
                    <table className='pay' style={{ marginTop: '-20px' }}>
                        <tr >
                            <td>Item Total</td>
                            <th>₹ {parseFloat(appointment?.paymentDetails.itemTotal).toFixed(2)}</th>
                        </tr>
                        <tr>
                            <td>Discount</td>
                            <th>₹ {parseFloat(appointment?.paymentDetails.discount).toFixed(2)}</th>
                        </tr>
                        {
                            appointment?.paymentDetails.couponDiscount ?
                                <>
                                    <tr>
                                        <td>Coupon Discount</td>
                                        <th className='danger'>- ₹ {parseFloat(appointment?.paymentDetails.couponDiscount).toFixed(2)}</th>
                                    </tr>
                                </>
                                : null
                        }
                        {
                            appointment?.paymentDetails.SEWallet ?
                                <>
                                    <tr>
                                        <td>Wallet Discount</td>
                                        <th className='danger'>- ₹ {parseFloat(appointment?.paymentDetails.SEWallet).toFixed(2)}</th>
                                    </tr>
                                </>
                                : null
                        }
                    </table>

                    <div className='total'>

                        <table>
                            <tr>
                                <td>Payable amount</td>
                                <td>
                                    {
                                        appointment.beauticianAssigned ?
                                            <>
                                                {
                                                    appointment?.beauticianDetails?.cashCollected === true ? <span className='success'>Paid</span> : <span className='danger'>Due</span>
                                                }
                                            </> :
                                            <>
                                                {
                                                    appointment.paid === true ? <span className='success'>Paid</span> : <span className='danger'>Due</span>
                                                }
                                            </>
                                    }


                                </td>
                                <th className='success'>₹ {parseFloat(appointment?.paymentDetails.payableAmount).toFixed(2)}</th>
                            </tr>
                        </table>

                        <div className='pay-button' >
                            {
                                appointment?.beauticianDetails?.otpVerify === true ?
                                    <>
                                        {
                                            appointment.paid === true ? null :
                                                <button onClick={() => { handelOnlinePayment(appointment) }}>{islodings ? <>PAY NOW</> : <BeatLoader color="#ffffff" size={10} />}</button>
                                        }
                                    </>
                                    : null
                            }

                        </div>
                    </div>

                </div>


                <div className='goodwishes'>
                    <h4>See you soon </h4>
                    <h4>Thankyou for using our services</h4>
                    <a href="https://g.page/r/CXD63HAHGmOzEBM/review" target='_blank'><p>Rate your experience <img src={Rating} width="100px" height="auto" style={{ marginTop: '-5px', marginLeft: "20px" }} /></p></a>
                </div>
            </div>
        </>
    )
}
