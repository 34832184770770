import React, { useState, useEffect, useRef } from 'react';
import './services.css';
import { useLocation, } from "react-router-dom";
import axios from 'axios';
import { serverAPI } from '../..';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './serviceDetailmodal.css';
import './addmypackmodal.css';
import BeatLoader from "react-spinners/BeatLoader"
import ServiceCard from './servicecard';
import ServiceCardMobile from './serviecardMobile';
import { useSelector } from "react-redux";
import { selectTotalCost, selectTotalTime } from "../../utils/cartSlice";
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";


export default function Servicelist() {
    const navigate = useNavigate();

    const location = useLocation();
    // const servicesRef = useRef(null);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [services, setService] = useState(null);
    const [servicesCat, setServiceCat] = useState(null);
    const [subID, setSubId] = useState(null);
    const [nameCat, setNameCat] = useState();
    const [categoryId, setCategoryId] = useState(null);
    const query = new URLSearchParams(location.search);
    const name = query.get('name');
    const service = query.get('service');
    const decodedName = decodeURIComponent(name);
    const decodedService = decodeURIComponent(service);
    const catid = query.get('id');
    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    const [cat, setCat] = useState('')
    const totalCost = useSelector(selectTotalCost);
    const totalTime = useSelector(selectTotalTime);

    useEffect(() => {
        if (!catid || !decodedName) {
            navigate('/service?name=Top%20selling%20Packages&id=651856c1909e90359c9a4788')
            setService([]);
        } else {
            setService([]);
        }

    }, [catid, decodedName])


    const getCatdata = () => {
        // Make the API call to fetch the product
        axios.get(`${serverAPI}/categories/list`)
            .then(response => {
                // Assuming the response data is in the format you provided
                setItems(response.data);
                // setIsLoading(false);

            })
            .catch(error => {
                console.error('Error fetching product:', error);
            });
    }
    useEffect(() => {
        getCatdata();
    }, []); // Fetch subcategories when selected category changes

    useEffect(() => {

        setTimeout(() => {
            setIsLoading(false);
        }, 2500);
    }, []);

    const [islodings, setIsLodings] = useState(true)
    const clicktonext = () => {
        setIsLodings(false);
        if (parseInt(totalCost) >= 749) {
            setTimeout(() => {
                navigate('/cart');
                setIsLodings(true);
            }, 1500);
        } else {
            toast.error("Please add more items to checkout (minimum checkout amount is 749)", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setTimeout(() => {
                setIsLodings(true);
            }, 1500);
        }

    }

    const handleCategoryClick = async (categoryID, catName) => {
        setIsLoading(true);
        setCategoryId(categoryID)
        if (categoryID) {
            await axios.get(`${serverAPI}/subcategories/list/${categoryID}`)
                .then(response => {
                    // Assuming the response data is in the format you provided
                    setSubcategories(response.data);
                    setIsLoading(false);
                    setNameCat(catName)
                })
                .catch(error => {
                    console.error('Error fetching subcategories:', error);
                });
        }
        axios.get(`${serverAPI}/categories/all/${categoryID}`)
            .then(response => {
                setServiceCat(null)
                setIsLoading(false);
                setService(response.data);
            })
            .catch(error => {
                setService(null);
                setIsLoading(true);
                console.error('Error fetching categories:', error);
            });
    }

    useEffect(() => {
        // Make the API call to fetch the categories
        axios.get(`${serverAPI}/categories`)
            .then(response => {
                // Assuming the response data is in the format you provided
                setCategories(response.data);

            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, [])

    useEffect(() => {
        // Make the API call to fetch the subcategories based on selected category
        if (catid) {
            axios.get(`${serverAPI}/subcategories/list/${catid}`)
                .then(response => {
                    // Assuming the response data is in the format you provided
                    setSubcategories(response.data);
                    setNameCat(response.data[0].category)
                })
                .catch(error => {
                    console.error('Error fetching subcategories:', error);
                });
        }

        // Fetch service data based on either category or subcategory ID
        if (catid && !subID) {
            axios.get(`${serverAPI}/categories/all/${catid}`)
                .then(response => {
                    setServiceCat(null)
                    setService(response.data);
                })
                .catch(error => {
                    setService(null);
                    console.error('Error fetching categories:', error);
                });
        } else if (subID) {
            axios.get(`${serverAPI}/subcategories/all/${subID}`)
                .then(response => {
                    setService(null);
                    setServiceCat(response.data);
                })
                .catch(error => {
                    setServiceCat(null);
                    console.error('Error fetching subcategories:', error);
                });
        }
    }, [catid, subID]);


    // let sectionRefs = [];
    let sectionRefss = {};

    // const scrollHandler = (name) => {
    //     if (sectionRefs[name]) {
    //         const container = document.querySelector('.moblie_service'); // Get the container element
    //         if (container) {
    //             container.scrollTo({
    //                 top: sectionRefs[name].offsetTop,
    //                 behavior: 'smooth',
    //             });
    //         }
    //     }
    // };



    // const handleClick = (index) => {
    //     if (activeIndex === index) {
    //         setActiveIndex(null); // Toggle to collapse if clicked again
    //     } else {
    //         setActiveIndex(index);
    //     }
    // };

    // const scrollHandlers = (sectionName) => {
    //     const section = sectionRefss[sectionName];
    //     if (section) {
    //         section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    // };




    const sectionRefs = {}; // Initialize sectionRefs as an object

    const scrollHandler = (name) => {
        const container = document.querySelector('.moblie_service'); // Get the container element
        if (container && sectionRefs[name]) {
            const elementOffsetTop = sectionRefs[name].offsetTop;
            const containerOffsetTop = container.offsetTop;
            const offset = elementOffsetTop - containerOffsetTop;
            container.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const isMobile = window.innerWidth < 768; // Adjust the threshold as needed for your definition of mobile size
        if (isMobile) {
            scrollHandler(decodedService);
        }
        scrollHandlers(decodedService)
    }, [decodedService, sectionRefs]);

    const handleClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null); // Toggle to collapse if clicked again
        } else {
            setActiveIndex(index);
        }
    };

    const scrollHandlers = (sectionName) => {
        setCat(sectionName);
        const section = sectionRefss[sectionName];
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    const updateURL = (name, id) => {
        const encodedName = encodeURIComponent(name);
        navigate(`/service?name=${encodedName}&id=${id}`);
        setService([])
    };

    // Inside your component
    const [activeSubcategory, setActiveSubcategory] = useState(''); // Initialize activeSubcategory state

    // Set the active subcategory when the component mounts
    useEffect(() => {
        if (subcategories.length > 0) {
            setActiveSubcategory(subcategories[0].name);
        }


    }, [subcategories]); // Trigger when subcategories change


    // Function to handle subcategory click
    const handleSubcategoryClick = (name) => {
        setActiveSubcategory(name);
        scrollHandler(name); // Call your scroll handler function here
    };



    useEffect(() => {
        const interval = setInterval(() => {
            const isMobile = window.innerWidth < 768; // Adjust the threshold as needed for your definition of mobile size
            if (isMobile) {
                scrollHandler(decodedService);
            }
        }, 1000); // Check every second (1000 milliseconds)

        return () => clearInterval(interval); // Clear the interval on component unmount

    }, [decodedService]); // Add any relevant dependencies


    // const categoryEntries = Object?.entries(services);

    return (
        <>
            {isLoading ? <div className="card_services_up">
                <div class="cat_silders">
                    <div class="cat_views">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <div className="cat_itmes" key={index}>
                                <span style={{ marginTop: '0px' }}><SkeletonTheme baseColor="#e6e6e654" highlightColor="#E6E6E6">
                                    <Skeleton circle={true} height={60} width={60} />
                                    <Skeleton width={60} />
                                </SkeletonTheme> </span>
                            </div>
                        ))}
                    </div>
                    <div className='subcat_silder'>
                        <div className='subcat_views'>
                            {Array.from({ length: 10 }).map((_, index) => (
                                <div className='subcat_button' key={index}>
                                    <span > <SkeletonTheme baseColor="#e6e6e654" highlightColor="#E6E6E6">
                                        <Skeleton width={100} />
                                    </SkeletonTheme></span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card_services">
                    <div className="card_services_serv">
                        <div className="card_services_heading">
                            <div className="section-heada">
                                <SkeletonTheme baseColor="#e6e6e654" highlightColor="#E6E6E6" width={100}>
                                    <p><Skeleton width={300} height={50} /> </p>
                                </SkeletonTheme>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="sidebar" style={{ backgroundColor: "#ffffff00", scrollbarColor: "#ffffff00" }}>
                                    <div className="accordion accordion-flush" id="accordionFlushExample" style={{ overflowY: 'hidden' }}>
                                        {Array.from({ length: 10 }).map((_, index) => (
                                            <span key={index}> <SkeletonTheme baseColor="#e6e6e654" highlightColor="#E6E6E6">
                                                <Skeleton height={48} />
                                            </SkeletonTheme></span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 servicebar_scrol">
                                {Array.from({ length: 10 }).map((_, index) => (

                                    <span key={index}> <SkeletonTheme baseColor="#e6e6e654" highlightColor="#E6E6E6">
                                        <Skeleton height={30} width={200} />
                                        <Skeleton height={350} />
                                    </SkeletonTheme></span>

                                ))}
                            </div>
                            <div className='moblie_service' style={{ paddingTop: "0px", top: '208px', position: 'fixed' }}>
                                {Array.from({ length: 10 }).map((_, index) => (

                                    <span key={index} > <SkeletonTheme baseColor="#e6e6e654" highlightColor="#E6E6E6" >
                                        <Skeleton height={30} width={200} />
                                        <Skeleton height={350} />
                                    </SkeletonTheme></span>

                                ))}
                            </div>
                        </div>
                    </div>
                </div >
            </div >

                :
                //setCategoryId  cat_itmesAct
                <div className="card_services_up">
                    <div class="cat_silders">
                        <div class="cat_views" style={{ cursor: 'pointer' }}>
                            {categories.map((categorie) => (
                                <div className={`cat_itmes ${decodedName === categorie.name ? 'cat_itmesAct' : ''}`} key={categorie._id} style={{ cursor: 'pointer' }}>
                                    <img style={{ cursor: 'pointer' }} onClick={() => {
                                        handleCategoryClick(categorie._id, categorie.name);
                                        updateURL(categorie.name, categorie._id);
                                    }} src={categorie.image} alt="none" />
                                    <span style={{ cursor: 'pointer' }} >{categorie.name}</span>
                                </div>
                            ))}
                        </div>

                        <div className='subcat_silder'>
                            <div className='subcat_views'>
                                {subcategories.map((subcategorie, index) => (
                                    <div
                                        className={`subcat_button ${subcategorie.name === activeSubcategory ? 'active' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        key={subcategorie._id}
                                        onClick={() => handleSubcategoryClick(subcategorie.name)}
                                    >
                                        <span style={{ cursor: 'pointer' }} className='textactive' onClick={() => scrollHandler(subcategorie.name)}>{subcategorie.name} </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="card_services " >
                        <div className="card_services_serv ">
                            <div className="card_services_heading">
                                <div className="section-heada" >
                                    <h4>
                                        {nameCat}
                                    </h4>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-4">
                                    {/* Your sidebar */}
                                    <div className="sidebar">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">

                                            <>
                                                {items.map((item, index) => (
                                                    <div className="accordion-item" key={index}>
                                                        <h2 className="accordion-header" id={`flush-heading${index}`}>
                                                            <button
                                                                className={`button-accordion accordion-button${decodedName === item.category ? '' : ' collapsed'}`}
                                                                type="button"
                                                                onClick={() => {
                                                                    handleClick(index);
                                                                    handleCategoryClick(item.categoryID, item.category);
                                                                    updateURL(item.category, item.categoryID);
                                                                }}
                                                                aria-expanded={activeIndex === index ? 'true' : 'false'}
                                                                aria-controls={`flush-collapse${index}`}
                                                            >
                                                                {item.category}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id={`flush-collapse${index}`}
                                                            className={`accordion-collapse collapse${decodedName === item.category ? ' show' : ''}`}
                                                            aria-labelledby={`flush-heading${index}`}
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="list-group">
                                                                    {item.subcategories.map((subcategory, menuIndex) => (
                                                                        <div
                                                                            style={{ cursor: 'pointer' }}
                                                                            className={`menu-list-itme  ${subcategory.name === cat ? 'mennlistactive' : ''}`}
                                                                            onClick={() => {
                                                                                scrollHandlers(subcategory.name); // Scroll to the corresponding section
                                                                            }}
                                                                            key={menuIndex}
                                                                        >
                                                                            <p style={{ cursor: 'pointer' }}> {subcategory.name}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>

                                        </div>
                                    </div>
                                </div>

                                {/* Your map service */}
                                <div className="col-8 servicebar_scrol" >
                                    {services ? (
                                        Object.entries(services).map(([subcategoryName, services]) => (
                                            <div key={subcategoryName} ref={(ref) => (sectionRefss[subcategoryName] = ref)} data-section-name={subcategoryName}>
                                                <h2 > {subcategoryName}</h2>
                                                {services.map((service, index) => (
                                                    <ServiceCard service={service} key={index} />
                                                ))}
                                            </div>
                                        ))
                                    ) : null}
                                </div>
                                {/* mobile view  */}

                                <div
                                    className='moblie_service'
                                    style={
                                        Math.round(totalCost) === 0
                                            ? { padding: "0px", position: 'fixed ', top: '180px' }
                                            : { padding: "0px", paddingBottom: '50px', position: 'fixed ', top: '180px' }
                                    }
                                >
                                    {services
                                        ? Object.keys(services).map((subcategoryName, categoryIndex) => {
                                            const category = services[subcategoryName];
                                            const categoryLength = category.length;
                                            return (
                                                <div key={subcategoryName} data-section-name={subcategoryName}>
                                                    <h2 style={{ paddingTop: '10px' }} ref={(ref) => (sectionRefs[subcategoryName] = ref)}>
                                                        {subcategoryName}
                                                    </h2>
                                                    {category.map((service, index) => (
                                                        <ServiceCardMobile
                                                            service={service}
                                                            key={index}
                                                            lastreach={categoryIndex === Object.keys(services).length - 1 && index === categoryLength - 1}
                                                        />
                                                    ))}
                                                </div>
                                            );
                                        })
                                        : null}
                                </div>

                            </div>
                        </div>
                    </div >
                    {Math.round(totalCost) === 0 ?
                        null : <div className="cart_cost">
                            <div className="cart_left">
                                <span className="total_Cost">Total Cost - ₹{parseInt(totalCost)}/-</span>
                                <span className="total_time">Total Time - {totalTime}</span>
                            </div>
                            <div className="cart_right" style={{ cursor: "pointer" }} onClick={clicktonext}>
                                <span className="service_cartbtn"> {islodings ? <>VIEW CART</> : <BeatLoader color="#ffffff" size={10} />}</span>
                            </div>
                        </div>}

                </div >
            }
        </>
    );
}


