// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { serverAPI } from '..';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Function to check user data
    const checkLoggedInUser = async () => {
      try {
        const response = await axios.get(`${serverAPI}/auth/is-login`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          const userData = response.data.user;
          setUserData(userData);
          setIsLoading(false);
        } else {
          // If the user is not logged in, set userData to null
          setUserData(null);
        }
      } catch (error) {
        // Handle errors here
        localStorage.removeItem("user");
        localStorage.removeItem("address");
        localStorage.removeItem("jwt");
      }
    };

    // Initial call to checkLoggedInUser
    checkLoggedInUser();
  }, []); // Empty dependency array ensures this effect runs once when mounted

  return (
    <UserContext.Provider value={{ userData, isLoading }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
