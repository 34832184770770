import React, { useEffect, useState } from 'react'
import NewHome from '../components/home/newHome'
import Footer from '../layouts/footer'
import Homeheader from '../layouts/home_header'
import HashLoader from "react-spinners/HashLoader";
import { Helmet } from "react-helmet";

export default function NewHomePage() {
  const [pageLoaded, setPageLoaded] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(false)
    }, 1800);
  }, [])

  return (
    <>{
      pageLoaded ?
        <>
          <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <HashLoader
              color="#981313"
              size={50}
            />
          </div>
        </>
        :
        <>
          <Helmet>
            <meta charSet="utf-8" />
            
          </Helmet>
          <Homeheader />
          <NewHome />
          <Footer />
        </>
    }
    </>
  )
}
