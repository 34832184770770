import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ProfileHeder from './profileHeder'
import LoginModal from '../login/loginModal'
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import { serverAPI } from '../..'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from "react-spinners/BeatLoader";
import './profile_Edit.css'
import ProfileMenu from './ProfileMenu'

export default function Userprofile() {
    const [isloding, setIsLoding] = useState(true)
    const [userData, setUserData] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpens, setIsPopupOpens] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        gender: '',
        birthday: '',
        alternateNumer: '',
    });
    const getRootUser = async () => {
        try {
            const res = await axios.get(`${serverAPI}/auth/is-login`, {
                withCredentials: true,
            });
            if (res.status === 200) {
                setPageLoaded(false);
                setUserData(res.data.user);
                setFormData({
                    name: res.data.user.name,
                    phone: res.data.user.phone,
                    email: res.data.user.email,
                    gender: res.data.user.gender,
                    birthday: res.data.user.birthday,
                    alternateNumer: res.data.user.alternateNumer,
                });

            }
        } catch (err) {
            setIsPopupOpens(true);
        }
    };

    useEffect(() => {
        getRootUser();
    }, []);

    const getFormattedAddress = () => {
        if (userData?.address.home && userData?.address.home.length > 0) {
            return userData?.address.home[0].address;
        } else if (userData?.address.other && userData?.address.other.length > 0) {
            return userData?.address.other[0].address;
        } else {
            return 'No address available';
        }
    };

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpens(false);
    };

    const closePopups = () => {
        navigate('/')
        setIsPopupOpens(false);
    };


    // const handleSaveDetails = async () => {
    //     setIsLoding(false)
    //     try {
    //         // Make an Axios POST request to save user details
    //         const response = await axios.put(`${serverAPI}/auth/user/${userData._id}`, formData);

    //         if (response.status === 200) {
    //             // Handle successful save
    //             toast.success("Profile Updated Succesfully 😃!", {
    //                 position: "top-center",
    //                 autoClose: 2000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 theme: "light",
    //             });
    //             setTimeout(() => {
    //                 setFormData({
    //                     name: response.data.name,
    //                     email: response.data.email,
    //                     gender: response.data.gender,
    //                     birthday: response.data.birthday,
    //                     alternateNumer: response.data.alternateNumer,
    //                 });
    //                 setIsLoding(true)
    //                 navigate('/profile')
    //             }, 2500);
    //         } else {
    //             // Handle save failure
    //             console.error('Error saving user details:', response.status);
    //         }
    //     } catch (error) {
    //         toast.error("somting wrong is happen 😃!", {
    //             position: "top-center",
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             theme: "light",
    //         });
    //         setIsLoding(true)
    //     }
    // };

    const handleSaveDetails = async () => {
        setIsLoding(false);

        // Check if any of the form data fields are empty
        if (!formData.name || !formData.email || !formData.gender || !formData.birthday) {
            if (!formData.name) {
                toast.warn("Please enter your name", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }
            if (!formData.email) {
                toast.warn("Please select your email", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }
            if (!formData.gender) {
                toast.warn("Please select your gender", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }
            if (!formData.birthday) {
                toast.warn("Please enter your birthday", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }


            setIsLoding(true);
            return;


        }

        // Email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(formData.email)) {
            toast.error("Invalid email address", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setIsLoding(true);
            return;
        }

        // Alternate number validation
        const alternateNumberRegex = /^\d{10}$/;
        if (!alternateNumberRegex.test(formData.alternateNumer)) {
            toast.error("Alternate Number should be 10 digits", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setIsLoding(true);
            return;
        }
        try {
            // Make an Axios PUT request to save user details
            const response = await axios.put(`${serverAPI}/auth/user/${userData._id}`, formData);

            if (response.status === 200) {
                // Handle successful save
                toast.success("Profile Updated Successfully 😃!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setTimeout(() => {
                    setFormData({
                        name: response.data.name,
                        email: response.data.email,
                        gender: response.data.gender,
                        birthday: response.data.birthday,
                        alternateNumer: response.data.alternateNumer,
                    });
                    setIsLoding(true);
                    navigate('/profile');
                }, 2500);
            } else {
                // Handle save failure
                console.error('Error saving user details:', response.status);
            }
        } catch (error) {
            toast.error("Something went wrong 😃!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setIsLoding(true);
        }
    };

    return (
        <> {
            pageLoaded ?
                <>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "88vh" }}>
                        <HashLoader
                            color="#981313"
                            size={50}
                        />

                    </div>
                    <LoginModal isOpen={isPopupOpens} onClose={closePopups} />

                </>
                :
                <div className='profile-parent'>
                    <div className='container'>

                        <div className='editprofile-wrap'>
                            <ProfileHeder name={userData.name} phone={userData.phone} address={getFormattedAddress()} />

                            <div className='Profile-divider'>
                                <ProfileMenu />
                                <div className='profile-content'>

                                    <div className='profile-detail'>
                                        <h4>Edit details</h4>


                                        <form>
                                            <div className="inputbox">
                                                <input
                                                    type="text"
                                                    name='name'
                                                    required
                                                    value={formData.name}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, name: e.target.value })
                                                    }
                                                />
                                                <span>Full Name</span>
                                            </div>
                                            <div className="inputbox">
                                                {
                                                    userData.phone ? <>
                                                        <input
                                                            type="number"
                                                            name='phone'
                                                            value={userData.phone}
                                                            disabled
                                                        />
                                                   
                                                    </> : <>

                                                        <input
                                                            type="number"
                                                            name='phone'
                                                            required
                                                            value={userData.phone}
                                                        />
                                                        <span>Mobile number</span>
                                                    </>
                                                }
                                            </div>
                                            <div className="inputbox">
                                                <input
                                                    type="email"
                                                    name='email'
                                                    required
                                                    value={formData.email}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, email: e.target.value })
                                                    }
                                                />
                                                <span>Email ID</span>
                                            </div>
                                            <div className="inputbox">
                                                <select
                                                    name="gender"
                                                    value={formData.gender}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, gender: e.target.value })
                                                    }
                                                >
                                                    <option value="">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div className="inputbox">
                                                <input
                                                    type="date"
                                                    name="birthday"
                                                    required
                                                    placeholder="DD/MM/YYYY"
                                                    value={formData.birthday}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, birthday: e.target.value })
                                                    }
                                                />
                                                {/* <span>Birthday dd/mm/yyyy</span> */}
                                            </div>
                                            <p>Alternate mobile details</p>
                                            <div className="inputbox" style={{ marginTop: '-20px' }}>
                                                <input
                                                    type='number'
                                                    name='alternateNumer'
                                                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                    required
                                                    value={formData.alternateNumer}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, alternateNumer: e.target.value })
                                                    }
                                                />
                                                <span>Alternate number</span>
                                            </div>
                                        </form>

                                        <div className='btn-wrap'>

                                            <div className='profil-editbtn' style={{ display: 'flex', justifyContent: 'center' }}>
                                                <button style={{ marginTop: "10px", width: "45%", marginRight: '25px' }} className='btn-1' onClick={() => { navigate(-1) }}>CANCEL</button>
                                                <button style={{ marginTop: "10px", width: "45%" }} onClick={handleSaveDetails}>{isloding ? <>SAVE DETAILS </> : <BeatLoader color="#ffffff" size={10} />}</button>
                                            </div>

                                        </div>




                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        }
        </>
    )
}
