import React, { useEffect, useState } from 'react'
import cancel from '../../assets/images/cancel.png';
import './serviceDetailmodal.css'
import Star from '../../assets/images/Star2.png'
import image7 from '../../assets/images/service.png'
import Back from '../../assets/images/arrow-left.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, incrementQuantity, decrementQuantity } from '../../utils/cartSlice';
import { toast } from "react-toastify";
import axios from 'axios';
import { serverAPI } from '../..';
import { selectTotalCost, selectTotalTime } from "../../utils/cartSlice";

export default function Servicepage() {
    const [service, setService] = useState([]);
    const [data, setdata] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(true);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    const totalCost = useSelector(selectTotalCost);
    const totalTime = useSelector(selectTotalTime);
    const splitTime = totalTime.split(' ')[0];
    const getCatdata = async () => {
        // Make the API call to fetch the product
        await axios.get(`${serverAPI}/services/${id}`)
            .then(response => {
                // Assuming the response data is in the format you provided
                setService(response.data);
                setdata(response.data)
                setPageLoaded(false);

            })
            .catch(error => {
            });
    }
    useEffect(() => {
        getCatdata();
    }, [service]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartProducts = useSelector((state) => state.cart.products);
    const isProductInCart = cartProducts.some((product) => product._id === service._id);
    const [count, setCount] = useState(isProductInCart ? cartProducts.find((product) => product._id === service._id).quantity : 1);

    const handleAddToCart = (e) => {
        if (splitTime <= 6) {
            dispatch(addToCart(service));
            setCount(1); // Reset the count to 1 when adding to cart
            toast.success("Successfully added in cart 🥳!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
        }
        else {

            toast.error("You have reached the time limit of 7 hours!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            return;
        }

    };
    const handleIncrement = () => {
        dispatch(incrementQuantity({ productId: service._id }));
        if (count !== 3) {
            setCount(count + 1);
        }
    };

    const handleDecrement = () => {
        dispatch(decrementQuantity({ productId: service._id }));
        setCount(count - 1);
    };

    // Split the description into individual services
    const services = data?.description?.split(', ').map(service => service.trim());
    // Define the template for each service item
    const serviceTemplate = service => <li key={service}><span>{service}</span></li>;

    // Split the description into individual services
    const recommended = data?.recommended_for?.split(', ').map(recommended => recommended.trim());
    // Define the template for each service item
    const recommendedTemplate = recommended => <li key={recommended}><span>{recommended}</span></li>;

    // Split the description into individual services
    const whatwebring = data?.what_we_will_bring?.split(', ').map(whatwebring => whatwebring.trim());
    // Define the template for each service item
    const whatwebringTemplate = whatwebring => <li key={whatwebring}><span>{whatwebring}</span></li>;

    // Split the description into individual services
    const prerequriment = data?.pre_requirements?.split(', ').map(prerequriment => prerequriment.trim());
    // Define the template for each service item
    const prerequrimentTemplate = prerequriment => <li key={prerequriment}><span>{prerequriment}</span></li>;

    // Split the description into individual services
    const aftersevrice = data?.after_service_instructions?.split(', ').map(aftersevrice => aftersevrice.trim());
    // Define the template for each service item
    const aftersevriceTemplate = aftersevrice => <li key={aftersevrice}><span>{aftersevrice}</span></li>;

    return (
        <>
            {
                pageLoaded ?
                    <>
                        <div className='Nav-bar' style={{ display: 'flex', alignItems: 'center', }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', }}>
                                <div onClick={() => { navigate(-1) }} style={{ cursor: "pointer", marginLeft: '10px', marginRight: '20px' }}>
                                    <img src={Back} width='18px' alt='' />
                                </div>
                                <div style={{ width: '80%' }}>
                                    <p style={{ padding: '0', margin: '0', textAlign: 'center' }}> {data?.subcategory} </p>
                                </div>
                            </div>

                        </div>
                        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" }}>
                            <HashLoader
                                color="#981313"
                                size={50}
                            />

                        </div>
                    </>
                    :
                    <>
                        <div className='Nav-bar' style={{ display: 'flex', alignItems: 'center', }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', }}>
                                <div onClick={() => { navigate(-1) }} style={{ cursor: "pointer", marginLeft: '10px', marginRight: '20px' }}>
                                    <img src={Back} width='18px' alt='' />
                                </div>
                                <div style={{ width: '80%' }}>
                                    <p style={{ padding: '0', margin: '0', textAlign: 'center' }}> {data?.subcategory} </p>
                                </div>
                            </div>

                        </div>
                        <div className="product-det d-flex  " style={{ justifyContent: "space-around" }}>
                            <div className="service_text">
                                <h6>{data.service_name}</h6>
                                <p style={{ marginTop: '20px' }}>{data.time}</p>
                                <p ><s>₹{parseInt(data.actual_price)}</s> <span className="mx-2 text-success">{parseFloat(data.discount).toFixed(2)}% off</span> ₹{parseInt(data.price)}/-</p>
                                <div className="rating">
                                    {/* <img src={Star} width="11px" height="11px" />
                                    <p> 4.8 (<span>623 Rating</span>)
                                    </p> */}
                                </div>
                            </div>
                            <div className="Service-img" style={{ width: "160px" }}>
                                <img src={data.image} alt={data.service_name} />
                            </div>
                        </div>



                        {
                            data?.description === 'na' ? null :
                                <div className="details2">
                                    <h5>Description</h5>
                                    <ul>
                                        {services?.map(serviceTemplate)}

                                    </ul>
                                </div>
                        }


                        {
                            data?.recommended_for === 'na' ?
                                null
                                :
                                <>
                                    <div className="details2">
                                        <h5>Recommended for</h5>
                                        <div className="list1">
                                            <ul>
                                                {recommended?.map(recommendedTemplate)}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                        }


                        {
                            data.pre_requirements === 'na' ?
                                null
                                :
                                <>
                                    <div className="details2">
                                        <h5>Pre requirements</h5>
                                        <div className="list1">
                                            <ul>
                                                {prerequriment?.map(prerequrimentTemplate)}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                        }

                        {/* <div className="details2">
                            <h5>About product</h5>
                            <p >A basic facial that cleanses, exfoliates, and
                                nourishes the skin, leaving
                                it refreshes and rejuvenated.A basic facial that cleanses, exfoliates, and nourishes the skin, leaving
                                it refreshes and rejuvenated.</p>
                        </div> */}

                        {
                            data.after_service_instructions === 'na' ?
                                null
                                :
                                <>
                                    <div className="details2" >
                                        <h5>After service instructions</h5>
                                        <div className="list1">
                                            <ul>
                                                {aftersevrice?.map(aftersevriceTemplate)}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                        }

                        {
                            data.what_we_will_bring === 'na' ?
                                null
                                :
                                <>
                                    <div className="details2" style={{ marginBottom: '3rem' }}>
                                        <h5>What we will bring</h5>
                                        <div className="list1">
                                            <ul>
                                                {whatwebring?.map(whatwebringTemplate)}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                        }

                        {/* <div className="details3">
                            <h5>Customers reviews</h5>
                            <div className="rating" style={{ marginLeft: '20px' }} >
                                <img src={Star} width="11px" height="11px" />
                                <p > 4.8 (<span>623 Rating</span>)
                                </p>
                            </div>
                            <div className="rating1 ">
                                <p>Kriti panday</p>
                                <img src={Star} width="11px" height="11px" />
                                <h6 >5</h6>
                            </div>
                            <p >Best service, thankyou salonease for making
                                beauty needs so affordable and easy</p>
                            <div className="rating1 ">
                                <p>Kriti panday</p>
                                <img src={Star} width="11px" height="11px" />
                                <h6 >5</h6>
                            </div>
                            <p >Best service, thankyou salonease for making
                                beauty needs so affordable and easy</p>
                            <div className="rating1">
                                <p>Kriti panday</p>
                                <img src={Star} width="11px" height="11px" />
                                <h6 >5</h6>
                            </div>
                            <p>Best service, thankyou salonease for making
                                beauty needs so affordable and easy</p>
                            <div className="rating1 ">
                                <p>Kriti panday</p>
                                <img src={Star} width="11px" height="11px" />
                                <h6 >5</h6>
                            </div>
                            <p >Best service, thankyou salonease for making
                                beauty needs so affordable and easy</p>
                        </div> */}

                        {/* footer */}

                        <div class="cart_footer" style={{position: 'fixed !important'}}>
                            <div class="cart_left">
                                <span class="total_Cost">Total Cost- ₹{parseInt(data.price)}/-</span>
                                <span class="total_time">Total Time- {data.time}</span>
                            </div>
                            <div class="cart_right">
                                {isProductInCart ? (
                                    <div className="service_cartbtn">
                                        <span onClick={handleDecrement}>-</span>
                                        <span className="counter">{count}</span>
                                        <span onClick={handleIncrement}>+</span>
                                    </div>
                                ) : (
                                    <button onClick={handleAddToCart}>ADD TO CART</button>
                                )}
                            </div>
                        </div>
                    </>
            }
        </>
    )
}
