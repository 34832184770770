import React from 'react'
import Clock from '../../assets/images/clock.png'
export default function trackAllComp({ appointment, index }) {

    return (
        <>
            <div className='recentorder' key={index} >
                <div className='card-head'>
                    <p className='parah1'>Booking no. - {appointment.orderID}</p>
                    <p className='parah2'> slot - {appointment.date + ", " + appointment.time}</p>
                </div>
                {appointment.cart.map((service, serviceIndex) => (
                    <div className='current-body' key={serviceIndex}>
                        <div className='ordr-dtl'>
                            <div className="odr-img">
                                <img src={service.image} alt={`Service ${serviceIndex + 1}`} />
                            </div>
                            <div className='ord-text'>
                                <div className='ord_details '>
                                    <div className='ord_box1'>
                                        <h4>{service.service_name}</h4>
                                        {
                                            service?.subNo > 0 ?
                                                <>
                                                    <ul>
                                                        {appointment.subService
                                                            .filter(item => item.checked === true)
                                                            .map((item, index) => (
                                                                <li key={index}>{item.name}</li>
                                                            ))}
                                                    </ul>

                                                </> : null
                                        }
                                    </div>
                                    <div className='odr-multiple'>
                                        <h6 > × {service.quantity}</h6>
                                    </div>
                                </div>
                                <div className='ord-rate'>
                                    <div className='rate1'>
                                        <h5> ₹ {parseInt(service.price)}/-</h5>
                                    </div>
                                    <div className='rate2'>
                                        <h4><img src={Clock} height='15px' width='15px' style={{ marginTop: '-3px', marginRight: '5px' }} alt='' />{service.time}</h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}
